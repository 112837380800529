exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-details-js": () => import("./../../../src/pages/Details.js" /* webpackChunkName: "component---src-pages-details-js" */),
  "component---src-pages-gift-registry-js": () => import("./../../../src/pages/gift-registry.js" /* webpackChunkName: "component---src-pages-gift-registry-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-archive-js": () => import("./../../../src/pages/index-archive.js" /* webpackChunkName: "component---src-pages-index-archive-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-places-to-stay-js": () => import("./../../../src/pages/PlacesToStay.js" /* webpackChunkName: "component---src-pages-places-to-stay-js" */),
  "component---src-pages-rsvp-js": () => import("./../../../src/pages/RSVP.js" /* webpackChunkName: "component---src-pages-rsvp-js" */)
}

